* {
  margin: 0;
  padding: 0;
}

body {
  color: #2a2354;
}

body,
Canvas {
  width: 100%;
  height: 100vh;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.primary {
  background-image: linear-gradient(25deg, red, orange);
  font-weight: 700;
  color: white;

  &:hover {
    background-image: linear-gradient(25deg, orange, red);
    cursor: pointer;
  }
}

.secondary {
  border: 1px solid rgb(97, 91, 97);
  font-weight: 700;
  color: black;

  &:hover {
    background-color: rgb(97, 91, 97);
    color: white;
    cursor: pointer;
  }
}
